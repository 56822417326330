import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAlerts from "../../hooks/useAlerts";
import { getTest, getTestResults } from "../../services/test.service";
import UserTestsList from "../user/components/UserTestsList";
import ITest from "../../types/ITest";
import { groupResults } from "../../utils/statsUtils";
import UserTestResultsList from "../user/components/UserTestResultsList";

export default function TestView(): JSX.Element {
  const { error } = useAlerts();
  const navigate = useNavigate();
  const { id = 0 } = useParams();

  const [test, setTest] = useState<ITest>();
  const [results, setResults] = useState<any>();


  useEffect(() => {
    if (id) {
      getTest(id)
        .then(({ data }) => {
          setTest(data);
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            error();
          }
          navigate(-1);
        });

      getTestResults(id)
        .then(({ data }) => {
          setResults(groupResults(data));
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            error();
          }
        });
    }
  }, []);

  return (
    <>
      <UserTestsList tests={test ? [test] : undefined} onlyStart={true} />
      <UserTestResultsList results={results} />
    </>
  );
}
