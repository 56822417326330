import { Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import ArrayLoadingSkeleton from "../../../components/ArrayLoadingSkeleton";
import { useParams, useNavigate, Link } from "react-router-dom";
import PaperStepper from "../../../components/PaperStepper";
import { getCompanyCardDetails } from "../../../services/company.service";
import { useTranslation } from "react-i18next";
import { GroupFilter } from "../../common/company/groupFilter";
import CompanyTestResultsStats from "../test/CompanyTestResultsStats";
import { CompanyTestResultsList, groupCompanyTestResults } from "../test/CompanyTestResultsList";
import HeaderWithIcon from "../../../components/HeaderWithIcon";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import ICardWithResults from "../../../types/ICardWithResults";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { themePalette } from "../../../themes/palette";
import { getGroupsFromList } from "../../../utils/filterUtils";
import IGroup from "../../../types/IGroup";
import useAlerts from "../../../hooks/useAlerts";
import { getStatsFromResults } from "../../../utils/statsUtils";
import IUserWithResults from "../../../types/IUserWithResults";
import IReport from "../../../types/IReport";
import { R_REPORT_VIEW, R_TEST_VIEW } from "../../../config/routes";

const CompanyRidesCard = (): JSX.Element => {
  const { t } = useTranslation();
  const { error } = useAlerts();
  const { id } = useParams();
  const navigate = useNavigate();

  const [results, setResults] = useState<ICardWithResults>();
  const [groupId, setGroupId] = useState<IGroup["id"]>(-1);

  const groupFilterHandler = (id: IGroup["id"]) => {
    setGroupId(id);
  };

  useEffect(() => {
    if (id) {
      getCompanyCardDetails(id)
        .then(({ data }) => {
          setResults(data);
        })
        .catch(() => {
          error();
        });
    }
  }, [id]);

  const getFilteredResults = () => {
    if (results) {
      if (groupId !== -1) {
        return results.results.filter((item) => item.user.groups.some((el) => el.id === groupId));
      } else {
        return results.results;
      }
    }
    return [];
  };

  const filteredResults = getFilteredResults();

  const card: ICardWithResults | undefined =
    results && groupId && groupId > 0
      ? {
          ...results,
          results: filteredResults,
          ...getStatsFromResults(filteredResults)
        }
      : results;

  const groupedFilteredResults: IUserWithResults<IReport>[] =
    groupCompanyTestResults(filteredResults);

  return (
    <Box>
      <PaperStepper steps={[t("Wybierz kartę"), t("Statystyki karty")]} step={1} />

      <Box mt={2}>
        <Link
          to=""
          className="back_button"
          onClick={() => navigate(-1)}
          style={{ textDecoration: "none", color: themePalette.primary.main }}
        >
          <ArrowBackIcon sx={{ verticalAlign: "top" }} /> <span>{t("Wstecz")}</span>
        </Link>
      </Box>

      <Box mt={2} mb={2}>
        <ArrayLoadingSkeleton data={[card]}>
          <Paper>
            <Box padding={2}>
              <Typography gutterBottom={false} variant="h5">
                {t("Statystyki karty ocen")}: {card?.name ?? ""}
              </Typography>
            </Box>
          </Paper>
        </ArrayLoadingSkeleton>
      </Box>

      <Paper sx={{ paddingTop: "1rem" }}>
        {results && (
          <GroupFilter list={getGroupsFromList(results.results)} onSelect={groupFilterHandler} />
        )}
      </Paper>

      <Paper sx={{ paddingTop: "2rem", marginTop: "1rem" }}>
        <CompanyTestResultsStats data={card} />
      </Paper>

      <Paper sx={{ margin: "1rem 0" }}>
        <HeaderWithIcon
          title={t("Karty")}
          count={groupedFilteredResults?.length}
          icon={DescriptionOutlinedIcon}
        />
      </Paper>

      <Paper>
        <CompanyTestResultsList data={groupedFilteredResults} linkTo={R_REPORT_VIEW} />
      </Paper>
    </Box>
  );
};

export default CompanyRidesCard;
