import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Box,
  Stack,
  Checkbox,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { themePalette } from "../../../themes/palette";
import { BACKEND_URL } from "../../../config/config";
import { getFormattedDate } from "../../../utils/dateUtils";
import ITestResultWithTestAndUser from "../../../types/ITestResultWithTestAndUser";
import ITestQuestion from "../../../types/ITestQuestion";

const TestResult = ({ test }: { test: ITestResultWithTestAndUser }): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const checkAnswerToCheckbox = (qID: number, aKey: string) => {
    const goodAnswers = test.questions;

    if (goodAnswers[qID]) {
      return goodAnswers[qID].some((element: string) => {
        return element === aKey;
      });
    }
  };

  const checkAnswerClassName = (qID: number, aKey: string) => {
    const userAnswers = test.answers;

    if (userAnswers[qID]) {
      return userAnswers[qID].some((element: string) => {
        return element === aKey;
      });
    }
  };

  const backButton = <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
    <Link to="" className="back_button" onClick={() => navigate(-1)} style={{ textDecoration: "none", color: themePalette.primary.main }}>
      <ArrowBackIcon sx={{ verticalAlign: 'top' }}/> <span>{t("Wstecz")}</span>
    </Link>
  </Stack>

  if (!test?.endedAt) {
    return <Grid container spacing={2}>
      <Grid item xs={12}>
        {backButton}
      </Grid>
      <Grid item xs={12}>
        <Alert severity="warning">{t("Egzamin nie został ukończony!")}</Alert>
      </Grid>
    </Grid>;
  }

  return (
    <Grid container spacing={2} pb={2}>
      <Grid item xs={12} pb={2}>
        {backButton}
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Grid container spacing={2} p={2} pt={0}>
            <Grid item xs={6}>
              <Typography variant="body1">
                <strong>{test.user.name}</strong> ({test.user.email})
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography align="right" variant="body1">
                {t("Uzyskany wynik")} {test.result}% -{" "}
                <strong>
                  {test.result > (test.test.whenSucceed ?? 0) ? t("ZDANY") : t("NIEZDANY")}
                </strong>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">
                {t("Rozpoczęcie")}: {getFormattedDate(test.createdAt).date}{" "}
                <strong>{getFormattedDate(test.createdAt).time}</strong>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography align="right" variant="body1">
                {t("Zakończenie")}: {getFormattedDate(test.endedAt).date}{" "}
                <strong>{getFormattedDate(test.endedAt).time}</strong>
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {test.test.questions?.map(
        (question: ITestQuestion, index: number, questions: ITestQuestion[]) => (
          <Grid item xs={12} key={question.id}>
            <Paper>
              <Box p={2}>
                <Typography display={"block"} align="right" variant="caption">
                  {t("Pytanie")} <strong>{index + 1}</strong>/{questions?.length}
                </Typography>
                {question.media && (
                  <Box sx={{ width: "100%" }}>
                    <Box
                      component="img"
                      sx={{
                        display: "block",
                        overflow: "hidden",
                        maxHeight: "80vh",
                        height: "100%",
                        width: "100%",
                        margin: "1rem auto"
                      }}
                      src={
                        question.media?.url ? `${BACKEND_URL}files/open/${question.media?.key}` : ""
                      }
                      alt={t("Zdjęcie do pytania")}
                    />
                  </Box>
                )}
                <Typography>{question.question?.pl}</Typography>
              </Box>

              <List dense className="answers_list">
                {Object.keys(question.answers).map((key: string) => {
                  if (question.answers[+key].pl === "") {
                    return null;
                  }

                  const labelId = `checkbox-list-label-${key}`;
                  const yourAnswer = checkAnswerClassName(question.id, key);
                  const goodAnswer = checkAnswerToCheckbox(question.id, key);

                  const className =
                    yourAnswer === true
                      ? goodAnswer === true
                        ? "answers_list__item--good"
                        : "answers_list__item--bad"
                      : "";

                  return (
                    <ListItemButton dense key={key} className={"answers_list__item " + className}>
                      <ListItemIcon>
                        <Checkbox
                          checked={goodAnswer}
                          disableRipple
                          inputProps={{ "aria-labelledby": labelId }}
                          disabled
                        />
                      </ListItemIcon>
                      <ListItemText id={labelId} primary={question.answers[+key].pl} />
                    </ListItemButton>
                  );
                })}
              </List>
            </Paper>
          </Grid>
        )
      )}
    </Grid>
  );
};

export default TestResult;
