import IRouteConfig from "../types/IRouteConfig";
import Settings from "../containers/settings";
import UserHome from "../containers/user/UserHome";
import TestView from "../containers/tests/testView";
import TestsAll from "../containers/tests/allTests";
import QuizsAll from "../containers/tests/allQuizes";
import ReportsAll from "../containers/reports/allReports";
import TestQuestions from "../containers/tests/testQuestions";
import TestFinish from "../containers/tests/testFinish";
import Ride from "../containers/trainer/TrainerRide";
import TrainerRideSummary from "../containers/trainer/TrainerRideSummary";
import ReportView from "../containers/reports/viewReport";
import IndexCompany from "../containers/company/comapnyUsers";
import CompanyUser from "../containers/company/companyUser";
import IndexAdmin from "../containers/admin";
import ElearningAll from "../containers/e-learning/allElearning";
import ElearningView from "../containers/e-learning/viewElearning";
import CompanyTest from "../containers/company/companyTest";
import TrainerReportsAll from "../containers/trainer/TrainerRideLists";
import CompanyTestStats from "../containers/company/companyTestStats";
import CompanyRidesList from "../containers/company/rides/CompanyRidesList";
import CompanyRidesCard from "../containers/company/rides/CompanyRidesCard";
import CompanyTests from "../containers/company/companyTests";
import TrainerHome from "../containers/trainer/TrainerHome";

// Default

export const R_INDEX = "/";
export const R_INDEX_ADMIN = "/admin";
export const R_LOGOUT = `/logout`;
export const R_LOGIN = `/login`;
export const R_NEW_PASSWORD = `/new-password`;
export const R_FIRST_LOGIN = `/first-login`;
export const R_REMIND = `/remind`;
export const R_SETTINGS = `/settings`;

// Tests

export const R_TESTS = `/tests`;
export const R_TEST_VIEW = (id: string | number = ":id") => `/test/${id}`;
export const R_TEST_QUESTIONS = (id: string | number = ":id") => `/test/start/${id}`;
export const R_TEST_FINISH = (id: string | number = ":id") => `/test/finish/${id}`;

// Quizes

export const R_QUIZS = `/quizes`;
export const R_QUIZ_VIEW = (id: string | number = ":id") => `/quiz/${id}`;

// Reports

export const R_REPORTS = `/reports`;
export const R_REPORT_VIEW = (id: string | number = ":id") => `/reports/${id}`;

// Rides

export const R_RIDE = (id: string | number = ":id") => `/ride/${id}`;
export const R_RIDE_FINISH = (id: string | number = ":id") => `/ride/result/${id}`;

// Elearning

export const R_ELEARNING = `/e-learning`;
export const R_ELEARNING_VIEW = (id: string | number = ":id") => `/e-learning/${id}`;

// Company

export const R_COMPANY_TESTS = `/tests`;

export const R_COMPANY_TESTS_TEST = (
  id: string | number = ":id",
) => `/tests/test/${id}`;

export const R_COMPANY_RIDES = `/rides`;
export const R_COMPANY_RIDES_CARD = (id: string | number = ":id") => `/rides/card/${id}`;
export const R_COMPANY_RIDE = (id: string | number = ":id") => `/ride/${id}`;

export const R_COMPANY_USERS = `/users`;
export const R_COMPANY_USER = (id: string | number = ":id") => `/users/${id}`;

// Role: Company

export const routesCompanyOwner: IRouteConfig[] = [
  {
    path: R_INDEX,
    breadcrumb: "Uczestnicy",
    container: IndexCompany
  },
  {
    path: R_COMPANY_TESTS,
    breadcrumb: "Teoria",
    container: CompanyTests
  },
  {
    path: R_COMPANY_TESTS_TEST(),
    breadcrumb: "Teoria",
    container: CompanyTestStats
  },
  {
    path: R_COMPANY_RIDES,
    breadcrumb: "Praktyka",
    container: CompanyRidesList
  },
  {
    path: R_COMPANY_RIDES_CARD(),
    breadcrumb: "Praktyka",
    container: CompanyRidesCard
  },
  {
    path: R_SETTINGS,
    breadcrumb: "Ustawienia",
    container: Settings
  },
  {
    path: R_COMPANY_USER(),
    breadcrumb: "Uczestnik",
    container: CompanyUser
  },
  {
    path: R_COMPANY_RIDE(),
    breadcrumb: "Raport z ewaluacji",
    container: ReportView
  },
  {
    path: R_TEST_VIEW(),
    breadcrumb: "Wyniki egzaminu",
    container: CompanyTest
  }
];

// Role: Admin

export const routesAdmin: IRouteConfig[] = [
  {
    path: R_INDEX_ADMIN,
    breadcrumb: "Wybór firmy",
    container: IndexAdmin
  },
  ...routesCompanyOwner
];

// Role: Trainer

export const routesTrainer: IRouteConfig[] = [
  {
    path: R_INDEX,
    breadcrumb: "Strona Główna",
    container: TrainerHome
  },
  {
    path: R_SETTINGS,
    breadcrumb: "Ustawienia",
    container: Settings
  },
  {
    path: R_RIDE(),
    breadcrumb: "Ewaluacja - w trakcie",
    container: Ride
  },
  {
    path: R_RIDE_FINISH(),
    breadcrumb: "Ewaluacja - podsumowanie",
    container: TrainerRideSummary
  },
  {
    path: R_REPORTS,
    breadcrumb: "Ewaluacje obserwowane",
    container: TrainerReportsAll
  }
];

// Role: User

export const routes: IRouteConfig[] = [
  {
    path: R_INDEX,
    breadcrumb: "Strona Główna",
    container: UserHome
  },
  {
    path: R_SETTINGS,
    breadcrumb: "Ustawienia",
    container: Settings
  },
  {
    path: R_TESTS,
    breadcrumb: "Egzaminy",
    container: TestsAll
  },
  {
    path: R_TEST_VIEW(),
    breadcrumb: "Test",
    container: TestView
  },
  {
    path: R_TEST_QUESTIONS(),
    breadcrumb: "Test - w trakcie",
    container: TestQuestions
  },
  {
    path: R_TEST_FINISH(),
    breadcrumb: "Test - zakończony",
    container: TestFinish
  },
  {
    path: R_QUIZS,
    breadcrumb: "Quizy",
    container: QuizsAll
  },
  {
    path: R_QUIZ_VIEW(),
    breadcrumb: "Quiz",
    container: TestView
  },
  {
    path: R_REPORTS,
    breadcrumb: "Raporty",
    container: ReportsAll
  },
  {
    path: R_REPORT_VIEW(),
    breadcrumb: "Raport - szczegóły",
    container: ReportView
  },
  {
    path: R_ELEARNING,
    breadcrumb: "Szkolenia",
    container: ElearningAll
  },
  {
    path: R_ELEARNING_VIEW(),
    breadcrumb: "Szkolenie - szczegóły",
    container: ElearningView
  }
];
