import { Grid, Paper } from "@mui/material";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import IEvent from "../../../types/IEvent";
import { useTranslation } from "react-i18next";
import EventListItem from "./EventListItem";
import { getBrowserLocale, getLanguage } from "../../../utils/languagePicker";
import ArrayLoadingSkeleton from "../../../components/ArrayLoadingSkeleton";
import styled from "@emotion/styled";

const StyleWrapper = styled.div`
  .fc-toolbar-title,
  .fc-day {
    text-transform: capitalize;
  }
`;

export default function UserEventCalendar({
  events
}: {
  events: IEvent[] | undefined;
}): JSX.Element {
  const { t } = useTranslation();

  const lang = getLanguage() || getBrowserLocale();

  return (
    <ArrayLoadingSkeleton emptyText={t("Brak wydarzeń do wyświetlenia")} data={events}>
      <Grid container component={Paper} p={2}>
        <Grid item xs={12} md={6}>
          <StyleWrapper>
            <FullCalendar
              plugins={[dayGridPlugin]}
              initialView="dayGridMonth"
              weekends={true}
              events={events?.map((event) => ({
                title: event.name,
                start: event.startTime,
                end: event.endTime,
                backgroundColor: event.color
              }))}
              firstDay={1}
              locale={lang}
            />
          </StyleWrapper>
        </Grid>
        <Grid item xs={12} md={6} p={2}>
          <Grid container>
            {events?.map((event: IEvent) => (
              <Grid item key={event.id} xs={12}>
                <EventListItem event={event} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </ArrayLoadingSkeleton>
  );
}
