import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import ArrayLoadingSkeleton from "../../../components/ArrayLoadingSkeleton";
import { useTranslation } from "react-i18next";
import IUserWithResults from "../../../types/IUserWithResults";
import StatsResultItem from "../../../components/StatsResultItem";
import { R_TEST_VIEW } from "../../../config/routes";
import ITestResultWithUser from "../../../types/ITestResultWithUser";
import { getStatsFromResults } from "../../../utils/statsUtils";
import IReport from "../../../types/IReport";

export const groupCompanyTestResults = <T extends IReport | ITestResultWithUser>(
  results: T[]
): IUserWithResults<T>[] => {
  const userResults = Object.values(
    results.reduce((acc: { [key: string]: T[] }, result: T) => {
      acc[result.user.id] = acc[result.user.id] || [];
      acc[result.user.id].push(result);
      return acc;
    }, {})
  );

  return userResults.map((values) => {
    return {
      ...values[0].user,
      ...getStatsFromResults(values),
      results: values
    };
  });
};

export function CompanyTestResultsList({
  data,
  linkTo
}: Readonly<{
  data?: IUserWithResults<IReport | ITestResultWithUser>[];
  linkTo: (id?: string | number) => string;
}>) {
  const { t } = useTranslation();

  return (
    <ArrayLoadingSkeleton emptyText={t("Brak wyników do wyświetlenia")} data={data}>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>{t("Uczestnik")}</TableCell>
              <TableCell align="center">{t("Progres")}</TableCell>
              <TableCell align="center">{t("Prób")}</TableCell>
              <TableCell align="right">{t("Wyniki")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((item) => (
              <StatsResultItem key={item.id} data={item} linkTo={linkTo} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ArrayLoadingSkeleton>
  );
}
