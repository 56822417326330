import { useEffect, useState } from "react";
import { getQuizes, getTests } from "../../services/test.service";
import useAlerts from "../../hooks/useAlerts";
import ITest from "../../types/ITest";
import { getReports } from "../../services/report.service";
import { getEvents } from "../../services/event.service";
import { groupReports } from "../../utils/statsUtils";
import UserEventCalendar from "./components/UserEventCalendar";
import UserReportsList from "../common/ride/ReportsList";
import IEvent from "../../types/IEvent";
import ICardWithResults from "../../types/ICardWithResults";
import UserTestsList from "./components/UserTestsList";
import { Grid } from "@mui/material";

export default function UserHome(): JSX.Element {
  const { error } = useAlerts();

  const [reports, setReports] = useState<ICardWithResults[]>();
  const [events, setEvents] = useState<IEvent[]>();
  const [tests, setTests] = useState<ITest[]>();
  const [quizes, setQuizes] = useState<ITest[]>();

  useEffect(() => {
    getEvents()
      .then(({ data }) => {
        setEvents(data);
      })
      .catch((err) => {
        if (err.response.status !== 401) {
          error();
        }
      });

    getTests()
      .then(({ data }) => {
        setTests(data);
      })
      .catch((err) => {
        if (err.response.status !== 401) {
          error();
        }
      });

    getQuizes()
      .then(({ data }) => {
        setQuizes(data);
      })
      .catch((err) => {
        if (err.response.status !== 401) {
          error();
        }
      });

    getReports()
      .then(({ data }) => {
        setReports(groupReports(data));
      })
      .catch((err) => {
        if (err.response.status !== 401) {
          error();
        }
      });
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <UserEventCalendar events={events} />
      </Grid>
      <Grid item xs={12}>
        <UserReportsList reports={reports} />
      </Grid>
      <Grid item xs={12}>
        <UserTestsList tests={tests} />
      </Grid>
      <Grid item xs={12}>
        <UserTestsList tests={quizes} isQuiz={true} />
      </Grid>
    </Grid>
  );
}
