import React, { KeyboardEventHandler, MutableRefObject, useEffect, useRef, useState } from "react";
import { Box, Grid, IconButton, Paper, Stack, TextField, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { getAllMessages, sendMessage } from "../../../services/chat-messages.service";
import IChatMessage from "../../../types/IChatMessage";
import { getTokenUser } from "../../../utils/tokenUtils";
import { Send } from "@mui/icons-material";

const ChatContainer = styled(Box)(({ theme }) => ({
  // height: "80vh",
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
  // boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
  border: `1px solid ${theme.palette.grey[400]}`,
  borderRadius: theme.shape.borderRadius,
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1)
}));

const MessagesContainer = styled(Box)({
  flex: 1,
  overflowY: "auto",
  padding: "20px",
  "&::-webkit-scrollbar": {
    width: "6px"
  },
  "&::-webkit-scrollbar-track": {
    background: "#f1f1f1"
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#888",
    borderRadius: "3px"
  }
});

const MessageBubble = styled(Box)<{ isUser: boolean }>(({ isUser }) => ({
  display: "flex",
  alignItems: "flex-start",
  marginBottom: "16px",
  flexDirection: isUser ? "row-reverse" : "row"
}));

const MessageContent = styled(Paper)<{ isUser: boolean }>(({ isUser }) => ({
  padding: "12px 16px",
  borderRadius: "16px",
  maxWidth: "70%",
  marginLeft: isUser ? 0 : "12px",
  marginRight: isUser ? "12px" : 0,
  backgroundColor: isUser ? "#2196f3" : "#f5f5f5",
  color: isUser ? "#fff" : "#000",
  transition: "all 0.2s ease-in-out",
  "&:hover": {
    transform: "scale(1.02)"
  }
}));

const InputContainer = styled(Box)({
  padding: "20px",
  borderTop: "1px solid rgba(0, 0, 0, 0.1)"
});

const Chat = ({ chatKey }: { chatKey: string }) => {
  const [messages, setMessages] = useState<IChatMessage[]>();
  const [newMessage, setNewMessage] = useState("");
  const messagesEndRef: MutableRefObject<any> = useRef(null);

  const currentUser = getTokenUser();

  useEffect(() => {
    getAllMessages(chatKey).then(({ data }) => setMessages(data));
  }, []);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const formatDate = (date: string) => {
    const obj = new Date(date);

    return obj.toLocaleString("pl-PL");
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSendMessage = () => {
    if (newMessage.trim()) {
      sendMessage(chatKey, newMessage).then(({ data }) => {
        setMessages((messages) => [...(messages || []), { ...data }]);
      });
      setNewMessage("");
    }
  };

  const handleKeyPress: KeyboardEventHandler = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ChatContainer>
          <MessagesContainer>
            {messages?.map((message, key) => (
              <MessageBubble key={key} isUser={message.sendBy === currentUser?.id}>
                {/*<Avatar*/}
                {/*  src={`https://${message.avatar}`}*/}
                {/*  alt={message.isUser ? "User" : "Contact"}*/}
                {/*  sx={{*/}
                {/*    width: 40,*/}
                {/*    height: 40*/}
                {/*  }}*/}
                {/*/>*/}
                <MessageContent isUser={message.sendBy === currentUser?.id}>
                  <Typography variant="body1" component="div">
                    {message.message}
                  </Typography>
                  <Typography variant="caption" sx={{ opacity: 0.7, mt: 0.5, display: "block" }}>
                    {formatDate(message.updatedAt)}
                  </Typography>
                </MessageContent>
              </MessageBubble>
            ))}
            <div ref={messagesEndRef} />
          </MessagesContainer>
          <InputContainer>
            <Stack direction="row" spacing={2}>
              <TextField
                fullWidth
                multiline
                maxRows={4}
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onKeyPress={handleKeyPress}
                placeholder="Type a message..."
                variant="outlined"
                size="small"
                sx={{ backgroundColor: "#fff" }}
                aria-label="Message input field"
              />
              <IconButton
                onClick={handleSendMessage}
                color="primary"
                aria-label="Send message"
                sx={{
                  backgroundColor: "#2196f3",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#1976d2"
                  }
                }}
              >
                <Send />
              </IconButton>
            </Stack>
          </InputContainer>
        </ChatContainer>
      </Grid>
    </Grid>
  );
};

export default Chat;
