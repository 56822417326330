import { Box, Backdrop, CircularProgress, Toolbar, Typography } from "@mui/material";
import React, { useState } from "react";
import "../../themes/App.css";
import { useLocation, Route, Routes, Navigate } from "react-router-dom";
import { routes, routesAdmin, routesCompanyOwner, routesTrainer } from "../../config/routes";
import {
  getRefreshTokenExpirationTimestamp,
  getTokenUser,
  getIsAuthenticated,
  setRefreshTokenExpirationTimestamp,
  getCompany,
  setIsAuthenticated,
  setTokenUser,
  setCompany
} from "../../utils/tokenUtils";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import Logout from "../auth/Logout";
import Login from "../auth/Login";
import axios from "axios";
import useAlerts from "../../hooks/useAlerts";
import { useTranslation } from "react-i18next";
import IUser from "../../types/IUser";
import { authRefresh } from "../../services/auth.service";
import TrainerHome from "../../containers/company/comapnyUsers";
import IRouteConfig from "../../types/IRouteConfig";

function App(): JSX.Element {
  const { t } = useTranslation();

  const { warning } = useAlerts();
  const [isSidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();

  axios.interceptors.request.use(
    (request) => {
      request.withCredentials = true;
      return request;
    },
    (error) => Promise.reject(error)
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (
        !error.response ||
        error.response?.status !== 401 ||
        !(
          error.response?.config?.url !== "/auth/refresh" &&
          error.response?.status !== 400
        )
      ) {
        return Promise.reject(error);
      }

      if (error.response.status === 401) {
        setLoading(true);

        const expirationTimestamp = getRefreshTokenExpirationTimestamp();
        const currentTokenUser = getTokenUser();
        const currentCompany = getCompany();

        if (expirationTimestamp && Date.now() < expirationTimestamp && currentTokenUser?.email) {
          return authRefresh(currentTokenUser.email, currentCompany?.id)
            .then(({ data }) => {
              if (data.user) {
                setTokenUser(data.user);
                setIsAuthenticated(true);
              }

              if (data.company?.id) {
                setCompany(data.company);
              }

              if (data.refreshTokenExpirationTimestamp) {
                setRefreshTokenExpirationTimestamp(data.refreshTokenExpirationTimestamp);
              }

              setLoading(false);

              return axios(error.config);
            })
            .catch((err) => Promise.reject(error));
        }
      }

      sessionStorage.clear();
      window.location.reload();
      warning(t("Sesja wygasła - zaloguj się ponownie"));
    }
  );

  function getFinalRoutes(user: IUser | null) {
    switch (user?.role) {
      case "User":
        return routes.map(({ path, container: Container }, index) => (
          <Route key={index} path={path} element={<Container />}></Route>
        ));
      case "Company":
        return routesCompanyOwner.map(({ path, container: Container }, index) => (
          <Route key={index} path={path} element={<Container />}></Route>
        ));
      case "Trainer":
        return routesTrainer.map(({ path, container: Container }, index) => (
          <Route key={index} path={path} element={<Container />}></Route>
        ));
      case "Admin":
        return routesAdmin.map(({ path, container: Container }, index) => (
          <Route key={index} path={path} element={<Container />}></Route>
        ));
    }
  }

  if (!getIsAuthenticated() && !getTokenUser()) {
    return <Login />;
  }

  return (
    <Box display="flex" height={"100vh"}>
      <Navbar onSidebarOpen={() => setSidebarOpen(true)} />
      <Sidebar onClose={() => setSidebarOpen(false)} open={isSidebarOpen} />
      <Box
        sx={{
          flexGrow: 1,
          padding: 2
        }}
        component={"main"}
      >
        <Toolbar />
        <Routes location={location}>
          {getFinalRoutes(getTokenUser())}
          <Route path="/logout" key={1} element={<Logout />}></Route>
          <Route path="*" key={0} element={<Navigate to="/" />}></Route>
        </Routes>
        <Backdrop open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </Box>
  );
}

export default App;
