import ArrayLoadingSkeleton from "../../../components/ArrayLoadingSkeleton";
import { useTranslation } from "react-i18next";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { R_COMPANY_RIDE } from "../../../config/routes";
import { getStatsColorByResult } from "../../../utils/statsUtils";
import { getFormattedDate } from "../../../utils/dateUtils";
import IReportWithCard from "../../../types/IReportWithCard";

export default function UserReportsList({
  reports
}: Readonly<{ reports: IReportWithCard[] | undefined }>) {
  const { t } = useTranslation();

  return (
    <ArrayLoadingSkeleton emptyText={t("Brak ewaluacji do wyświetlenia")} data={reports}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">{t("Data")}</TableCell>
              <TableCell align="left">{t("Wynik")}</TableCell>
              <TableCell align="left">{t("Ewaluacja")}</TableCell>
              <TableCell align="center">{t("Instruktor")}</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reports?.map((ride) => (
              <TableRow key={ride.id} hover>
                <TableCell align="left">
                  <Typography variant="caption">
                    {getFormattedDate(ride.createdAt).date} {getFormattedDate(ride.createdAt).time}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography
                    variant="subtitle1"
                    style={{ color: getStatsColorByResult(ride.result ?? 0) }}
                  >
                    {ride.result}%
                  </Typography>
                </TableCell>
                <TableCell align="left">{ride?.card?.name}</TableCell>
                <TableCell align="center">{ride?.instructor.name}</TableCell>
                <TableCell align="right">
                  <Button variant="outlined" href={R_COMPANY_RIDE(ride.id)}>{t("Szczegóły")}</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ArrayLoadingSkeleton>
  );
}
