import axios, { AxiosResponse } from "axios";
import IChatMessage, { ChatType } from "../types/IChatMessage";

export const getAllMessages = async (key: string): Promise<AxiosResponse<IChatMessage[]>> =>
  axios.get(`/chat-messages/${key}`);
export const sendMessage = async (
  key: string,
  message: string,
  chatType: ChatType = ChatType["REPORT-COMPANY-CHAT"]
): Promise<AxiosResponse<IChatMessage>> =>
  axios.post(`/chat-messages/${key}`, {
    type: chatType,
    message
  });
export const removeMessage = async (id: string) => axios.delete(`/chat-messages/${id}`);
