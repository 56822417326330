import createPalette from "@mui/material/styles/createPalette";

export const themePalette = createPalette({
  mode: "light",
  grey: {
    100: "#fafafa",
    200: "#f5f5f5",
    300: "#f0f0f0",
    400: "#dedede",
    500: "#c2c2c2",
    600: "#979797",
    700: "#818181",
    800: "#606060",
    900: "#3c3c3c"
  },
  action: {
    active: "#f15a22",
    focus: "rgba(55, 65, 81, 0.12)",
    hover: "rgba(55, 65, 81, 0.04)",
    selected: "rgba(55, 65, 81, 0.08)",
    disabledBackground: "rgba(55, 65, 81, 0.12)",
    disabled: "rgba(55, 65, 81, 0.26)"
  },
  background: {
    default: "#F9FAFC",
    paper: "#FFFFFF"
  },
  divider: "#E6E8F0",
  primary: {
    main: "#f15a22",
    light: "#f37b4e",
    dark: "#a83e17",
    contrastText: "#FFFFFF"
  },
  secondary: {
    light: "#4d6f4d",
    main: "#214b21",
    dark: "#173417",
    contrastText: "#FFFFFF"
  },
  success: {
    main: "#00E676",
    light: "#B9F6CA",
    dark: "#00C853",
    contrastText: "#FFFFFF"
  },
  info: {
    main: "#2196F3",
    light: "#64B6F7",
    dark: "#0B79D0",
    contrastText: "#FFFFFF"
  },
  warning: {
    main: "#ff9800",
    light: "#FFF8E1",
    dark: "#FFC107",
    contrastText: "#FFFFFF"
  },
  error: {
    main: "#F44336",
    light: "#EF9A9A",
    dark: "#C62828",
    contrastText: "#FFFFFF"
  },
  text: {
    primary: "#1f1f1f",
    secondary: "#2a2a2a",
    disabled: "rgba(55, 65, 81, 0.48)"
  }
});
