import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import ArrayLoadingSkeleton from "../../../components/ArrayLoadingSkeleton";
import { useTranslation } from "react-i18next";
import { getFormattedDate } from "../../../utils/dateUtils";
import { getStatsColorByResult } from "../../../utils/statsUtils";
import ITestWithResults from "../../../types/ITestWithResults";
import ICardWithResults from "../../../types/ICardWithResults";

export default function CompanyTestResultsStats({
  data
}: Readonly<{
  data: ITestWithResults | ICardWithResults | undefined;
}>) {
  const { t } = useTranslation();

  return (
    <ArrayLoadingSkeleton data={[data]}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("Statystyki")}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              hover
              sx={{
                "&:last-child td, &:last-child th": { border: 0 }
              }}
            >
              <TableCell>{t("Wyniki")}</TableCell>
              <TableCell align="right">
                <Typography variant="h6">
                  {t("Max")} -{" "}
                  <strong style={{ color: getStatsColorByResult(data?.max ?? 0) }}>
                    {data?.max ?? 0}%
                  </strong>
                </Typography>
                <Typography variant="h6">
                  {t("Średnia")} -{" "}
                  <strong style={{ color: getStatsColorByResult(data?.avg ?? 0) }}>
                    {Math.round(data?.avg ?? 0)}%{" "}
                  </strong>
                </Typography>
                <Typography variant="h6">
                  {t("Min")} -{" "}
                  <strong style={{ color: getStatsColorByResult(data?.min ?? 0) }}>
                    {data?.min ?? 0}%
                  </strong>
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow
              hover
              sx={{
                "&:last-child td, &:last-child th": { border: 0 }
              }}
            >
              <TableCell>
                {t("Najwyższy wynik")} -{" "}
                <Typography variant="h6" sx={{ display: "inline-block" }}>
                  <strong
                    style={{ color: getStatsColorByResult(data?.highestResult?.result ?? 0) }}
                  >
                    {data?.highestResult?.result ?? 0}%
                  </strong>
                </Typography>
              </TableCell>
              {data?.highestResult ? (
                <TableCell align="right">
                  <Typography variant="h6">
                    <strong>{data?.highestResult?.user?.name ?? ""}</strong>
                  </Typography>
                  {getFormattedDate(data?.highestResult?.createdAt).date}{" "}
                  {getFormattedDate(data?.highestResult?.createdAt).time}
                </TableCell>
              ) : (
                <TableCell></TableCell>
              )}
            </TableRow>
            <TableRow
              hover
              sx={{
                "&:last-child td, &:last-child th": { border: 0 }
              }}
            >
              <TableCell>
                {t("Najniższy wynik")} -{" "}
                <Typography variant="h6" sx={{ display: "inline-block" }}>
                  <strong style={{ color: getStatsColorByResult(data?.lowestResult?.result ?? 0) }}>
                    {data?.lowestResult?.result ?? 0}%
                  </strong>
                </Typography>
              </TableCell>
              {data?.lowestResult ? (
                <TableCell align="right">
                  <Typography variant="h6">
                    <strong>{data?.lowestResult?.user?.name ?? ""}</strong> <br />
                  </Typography>
                  {getFormattedDate(data?.lowestResult?.createdAt).date}{" "}
                  {getFormattedDate(data?.lowestResult?.createdAt).time}
                </TableCell>
              ) : (
                <TableCell></TableCell>
              )}
            </TableRow>
            <TableRow
              hover
              sx={{
                "&:last-child td, &:last-child th": { border: 0 }
              }}
            >
              <TableCell>{t("Ilość uczestników")}</TableCell>
              <TableCell align="right">{data?.usersCount}</TableCell>
            </TableRow>
            <TableRow
              hover
              sx={{
                "&:last-child td, &:last-child th": { border: 0 }
              }}
            >
              <TableCell>{t("Wszystkich podejść do egzaminu")}</TableCell>
              <TableCell align="right">{data?.attempts}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </ArrayLoadingSkeleton>
  );
}
