import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import useAlerts from "../../hooks/useAlerts";
import { getCompanyTests } from "../../services/company.service";
import { useTranslation } from "react-i18next";
import PaperStepper from "../../components/PaperStepper";
import ArrayLoadingSkeleton from "../../components/ArrayLoadingSkeleton";
import HeaderWithIcon from "../../components/HeaderWithIcon";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import CompanyStatsList from "./test/CompanyStatsList";
import { R_COMPANY_TESTS_TEST } from "../../config/routes";
import IStats from "../../types/IStats";
import ITest from "../../types/ITest";
import ITestResultWithUser from "../../types/ITestResultWithUser";

const CompanyTests = (): JSX.Element => {
  const { t } = useTranslation();
  const { error } = useAlerts();

  const [tests, setTests] = useState<(ITest & IStats<ITestResultWithUser>)[]>();

  useEffect(() => {
    getCompanyTests()
      .then(({ data }) => {
        setTests(data);
      })
      .catch(() => {
        error();
      });
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PaperStepper steps={[t("Wybierz egzamin"), t("Statystyki egzaminu")]} step={0} />
      </Grid>

      <Grid item xs={12}>
        <ArrayLoadingSkeleton data={tests}>
          <HeaderWithIcon title={"Egzaminy"} count={tests?.length} icon={DescriptionOutlinedIcon} />
        </ArrayLoadingSkeleton>
      </Grid>

      <Grid item xs={12}>
        <CompanyStatsList list={tests} route={R_COMPANY_TESTS_TEST} />
      </Grid>
    </Grid>
  );
};

export default CompanyTests;
