import IGroup from "../types/IGroup";
import IReport from "../types/IReport";
import ITestResultWithUser from "../types/ITestResultWithUser";
import IUserWithResults from "../types/IUserWithResults";

export const paramFilterExtra = (params: Record<string, string | number>) =>
  Object.entries(params).reduce(
    (acc, [key, value], index) =>
      `${acc}${index !== 0 ? "&" : ""}filter[${key}][%3D]=${"" + value}`,
    ""
  );

//TODO: add value class
export const paramFilter = (column: string, value: any, operator = "%3D") =>
  `&filter[${column}][${operator}]=${value}`;

export const paramSort = (column: string, sort = "ASC") => `&sort[${column}]=${sort}`;

export const paramLimit = (param = 10) => `&limit=${param}`;

export const paramOffset = (param = 10) => `&offset=${param}`;

export const getGroupsFromList = (
  list: IReport[] | ITestResultWithUser[]
): Pick<IGroup, "name" | "id">[] => {
  const groups: Map<number, Pick<IGroup, "name" | "id">> = new Map();

  list.forEach((item) => {
    item.user.groups.forEach(({ id, name }) => {
      groups.set(id, { id, name });
    });
  });

  return [...groups.values()];
};
