import axios, { AxiosPromise } from "axios";
import ITest from "../types/ITest";
import IUser from "../types/IUser";
import ICompany from "../types/ICompany";
import ICard from "../types/ICard";
import ITestResult from "../types/ITestResult";
import IGroupWithCounts from "../types/IGroupWithCompany";
import ITestWithResults from "../types/ITestWithResults";
import IUserWithResultsAndRides from "../types/IUserWithResultsAndRides";
import IStats from "../types/IStats";
import ITestResultWithUser from "../types/ITestResultWithUser";
import IReport from "../types/IReport";
import ICardWithResults from "../types/ICardWithResults";

export const getCompanyGroups = (): AxiosPromise<IGroupWithCounts[]> => axios.get(`/api/company/groups`)

export const getCompanyTests = (): AxiosPromise<(ITest & IStats<ITestResultWithUser>)[]> =>
  axios.get("/api/company/tests");

export const getCompanyTestDetails = (test_id: number | string): AxiosPromise<ITestWithResults> => axios.get(`/api/company/tests/${test_id}`);

export const getCompanyUsers = (): AxiosPromise<IUser[]> => axios.get("/api/company/users");

export const getCompanyUser = (user_id: number | string): AxiosPromise<IUserWithResultsAndRides> =>
  axios.get(`/api/company/users/${user_id}`);

export const getCompanyCards = (): AxiosPromise<(ICard & IStats<IReport>)[]> =>
  axios.get("/api/company/cards");

export const getCompanyGroupDetails = (group_id: number | string): AxiosPromise =>
  axios.get(`/api/group/${group_id}`);

export const getCompanyCardDetails = (card_id: number | string): AxiosPromise<ICardWithResults> => axios.get(`/api/company/cards/${card_id}`);

// export const getCompanyCardList = (card_id: number | string): AxiosPromise => axios.get(`/api/company/cards/${card_id}/list`);

export const getCompanies = (): AxiosPromise<[ICompany[], number]> => axios.get("/companies");

export const setCompanyToken = (company_id: number | string): AxiosPromise =>
  axios.post(`/auth/pick-company`, { company: company_id });

export const getCompanyUserRides = (userId: number | string): AxiosPromise<any[]> => axios.get(`/api/cards/rides/user/${userId}`);

export const getCompanyUserTests = (userId: number | string): AxiosPromise<ITest[]> =>
  axios.get(`/api/tests/user/${userId}`);
export const getCompanyUserTestsResults = (
  userId: number | string
): AxiosPromise<(ITestResult & { test: ITest })[]> => //todo: refactor return type
  axios.get(`/api/tests/user/${userId}/results`);
// export const getCompanyUserTestResults = (testId: number | string): AxiosPromise => axios.get(`/api/tests/${testId}/results/company`)
export const getCompanyTestResultsByUser = (
  userId: number | string,
  testId: number | string
): AxiosPromise => axios.get(`/api/tests/${testId}/results/users/${userId}`);
export const getCompanyUserTestResult = (resultId: number | string): AxiosPromise =>
  axios.get(`/api/tests/results/${resultId}`);
