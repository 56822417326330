import React, { useState } from "react";
import {
  Collapse,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { getFormattedDate } from "../utils/dateUtils";
import { getStatsColorByResult } from "../utils/statsUtils";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TestResultsLineChart from "../containers/company/user/TestResultsLineChart";
import ITestResult from "../types/ITestResult";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { useNavigate } from "react-router-dom";
import IUserWithResults from "../types/IUserWithResults";
import ICardWithResults from "../types/ICardWithResults";
import IReport from "../types/IReport";
import ITestWithResults from "../types/ITestWithResults";
import { theme } from "../themes";
import ITestResultWithUser from "../types/ITestResultWithUser";

export default function StatsResultItem({
  data,
  linkTo
}: {
  data: IUserWithResults<IReport | ITestResultWithUser> | ICardWithResults | ITestWithResults;
  linkTo: (id?: string | number) => string;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography>{data.name}</Typography>
        </TableCell>
        <TableCell align="center" sx={{ overflow: "hidden", maxWidth: 187 }}>
          <TestResultsLineChart data={data.results.length === 1 ? [data.results[0], data.results[0]] : data.results} />
        </TableCell>
        <TableCell align="center">
          <Typography variant="button" color="primary">
            {data.attempts}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <Typography variant="h6">
            {t("Średnia")} -{" "}
            <strong style={{ color: getStatsColorByResult(data.avg ?? 0) }}>
              {Math.round(data.avg ?? 0)}%
            </strong>
          </Typography>
          <Tooltip
            describeChild
            title={`${getFormattedDate(data.highestResult?.createdAt).date} ${
              getFormattedDate(data.highestResult?.createdAt).time
            }`}
            arrow
            placement="right"
          >
            <Link color={"secondary"}>
              <Typography variant="body2">
                {t("Najwyższy wynik")} -{" "}
                <strong style={{ color: getStatsColorByResult(data.highestResult?.result ?? 0) }}>
                  {data.highestResult?.result}%
                </strong>
              </Typography>
            </Link>
          </Tooltip>
          <Tooltip
            title={`${getFormattedDate(data.lowestResult?.createdAt).date} ${
              getFormattedDate(data.lowestResult?.createdAt).time
            }`}
            arrow
            placement="right"
          >
            <Link color={"secondary"}>
              <Typography variant="body2">
                {t("Najniższy wynik")} -{" "}
                <strong style={{ color: getStatsColorByResult(data.lowestResult?.result ?? 0) }}>
                  {data.lowestResult?.result}%
                </strong>
              </Typography>
            </Link>
          </Tooltip>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>{t("Rozpoczęcie")}</TableCell>
                  <TableCell>{t("Zakończenie")}</TableCell>
                  <TableCell align="center">{t("Wynik")}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.results.map((result: ITestResult | IReport) => (
                  <TableRow key={result.id}>
                    <TableCell>
                      <Typography variant="body2">
                        {getFormattedDate(result.createdAt).date}{" "}
                        <strong>{getFormattedDate(result.createdAt).time}</strong>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {result.endedAt ? (
                        <Typography variant="body2">
                          {getFormattedDate(result.endedAt).date}{" "}
                          <strong>{getFormattedDate(result.endedAt).time}</strong>
                        </Typography>
                      ) : (
                        <Typography variant="body2" color={theme.palette.error.main}>
                          {t("Nie ukończono")}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body1">
                        <strong style={{ color: getStatsColorByResult(result.result ?? 0) }}>
                          {result.result ?? 0}%
                        </strong>
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        size="small"
                        title={t("Szczegóły")}
                        onClick={() => navigate(linkTo(result.id))}
                      >
                        <VisibilityOutlinedIcon sx={{ fontSize: "1.15rem" }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
