import {
  Button,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from "@mui/material";
import ArrayLoadingSkeleton from "../../../components/ArrayLoadingSkeleton";
import { useTranslation } from "react-i18next";
import { getStatsColorByResult } from "../../../utils/statsUtils";
import { useNavigate } from "react-router-dom";
import ITest from "../../../types/ITest";
import IStats from "../../../types/IStats";
import ITestResultWithUser from "../../../types/ITestResultWithUser";
import ICard from "../../../types/ICard";
import IReport from "../../../types/IReport";

export default function CompanyStatsList({
  list,
  route
}: Readonly<{
  list: (ITest & IStats<ITestResultWithUser>)[] | (ICard & IStats<IReport>)[] | undefined;
  route: (primaryId: string | number, secondaryId?: string | number) => string;
}>) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <ArrayLoadingSkeleton emptyText={t("Brak egzaminów do wyświetlenia")} data={list}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("Nazwa")}</TableCell>
              <TableCell align="center">{t("Prób")}</TableCell>
              <TableCell>{t("Wyniki")}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list
              // ?.filter((item) => item.timeOut > 0)
              ?.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>
                    <Typography variant={"body1"}>{item.name}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="button" color="primary">
                      {item.attempts ?? 0}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">
                      {t("Średnia")} {" - "}
                      <strong style={{ color: getStatsColorByResult(item?.avg ?? 0) }}>
                        {Math.round(item.avg ?? 0)}%
                      </strong>
                    </Typography>
                    <Tooltip
                      describeChild
                      title={item.highestResult?.user?.name}
                      arrow
                      placement="right"
                    >
                      <Link color={"secondary"}>
                        <Typography variant="body2">
                          {t("Najwyższy wynik")} -{" "}
                          <strong style={{ color: getStatsColorByResult(item?.max ?? 0) }}>
                            {item.max ?? 0}%
                          </strong>
                        </Typography>
                      </Link>
                    </Tooltip>
                    <Tooltip title={item.lowestResult?.user?.name} arrow placement="right">
                      <Link color={"secondary"}>
                        <Typography variant="body2">
                          {t("Najniższy wynik")} -{" "}
                          <strong style={{ color: getStatsColorByResult(item?.min ?? 0) }}>
                            {item.min ?? 0}%
                          </strong>
                        </Typography>
                      </Link>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      variant="outlined"
                      size="small"
                      color="primary"
                      onClick={() => navigate(route(item.id))}
                    >
                      {t("Szczegóły")}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ArrayLoadingSkeleton>
  );
}
