import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import PaperStepper from "../../../components/PaperStepper";
import { R_INDEX_ADMIN } from "../../../config/routes";
import { getCompanyCards } from "../../../services/company.service";
import ICard from "../../../types/ICard";
import { getCompany, getTokenUser } from "../../../utils/tokenUtils";
import { useTranslation } from "react-i18next";
import useAlerts from "../../../hooks/useAlerts";
import CompanyStatsList from "../test/CompanyStatsList";
import { R_COMPANY_RIDES_CARD } from "../../../config/routes";
import ArrayLoadingSkeleton from "../../../components/ArrayLoadingSkeleton";
import HeaderWithIcon from "../../../components/HeaderWithIcon";
import IStats from "../../../types/IStats";
import IReport from "../../../types/IReport";

const CompanyRidesList = (): JSX.Element => {
  const { t } = useTranslation();
  const { error } = useAlerts();
  const navigate = useNavigate();

  const [cards, setCards] = useState<(ICard & IStats<IReport>)[]>();

  useEffect(() => {
    const currentUser = getTokenUser();
    const currentCompany = getCompany();

    if (!currentCompany?.id && currentUser?.role === "Admin") {
      error(t("Wybierz firmę"));
      navigate(R_INDEX_ADMIN);
      return;
    }

    getCompanyCards().then(({ data }) => {
      setCards(
        data.sort((a: ICard, b: ICard) => {
          if (a.name.toUpperCase() > b.name.toUpperCase()) {
            return -1;
          }
          if (b.name.toUpperCase() > a.name.toUpperCase()) {
            return 1;
          }
          return 0;
        })
      );
    });
  }, []);

  return (
    <Box>
      <PaperStepper steps={[t("Wybierz kartę"), t("Statystyki karty")]} step={0} />

      <Grid item xs={12} pt={2} pb={2}>
        <ArrayLoadingSkeleton data={cards}>
          <HeaderWithIcon title={t("Karty")} count={cards?.length} icon={DescriptionOutlinedIcon} />
        </ArrayLoadingSkeleton>
      </Grid>

      <CompanyStatsList list={cards} route={R_COMPANY_RIDES_CARD} />
    </Box>
  );
};

export default CompanyRidesList;
