export enum ChatType {
  'REPORT-COMPANY-CHAT' = 'REPORT-COMPANY-CHAT',
  'REPORT-CHAT' = 'REPORT-CHAT',
}

export default interface IChatMessage {
  id: number;
  message: string;
  key: string;
  type: ChatType;
  sendBy: number;
  createdAt: string;
  updatedAt: string;
}
