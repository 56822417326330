import { Grid, IconButton, Paper, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ArrayLoadingSkeleton from "../../../components/ArrayLoadingSkeleton";
import { R_QUIZ_VIEW, R_TEST_QUESTIONS, R_TEST_VIEW } from "../../../config/routes";
import AccessTimeFilledOutlinedIcon from "@mui/icons-material/AccessTimeFilledOutlined";
import ITest from "../../../types/ITest";
import { useNavigate } from "react-router-dom";
import { theme } from "../../../themes";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

export default function UserTestsList({
  tests,
  isQuiz,
  onlyStart
}: {
  tests: ITest[] | undefined;
  isQuiz?: boolean;
  onlyStart?: boolean;
}): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <ArrayLoadingSkeleton
      emptyText={t(`Brak ${isQuiz ? "quizów" : "egzaminów"} do wyświetlenia`)}
      data={tests}
    >
      <Paper sx={{ p: { xs: 2, sm: 3 }, mt: 1, mb: 3 }}>
        {tests &&
          tests?.map((test) => (
            <Grid
              container
              key={test.id}
              sx={{ alignItems: "center", justifyContent: "space-between" }}
            >
              <Grid item>
                <Stack direction="column">
                  <Typography variant="body1">{t("Egzamin")}</Typography>
                  <Typography variant="h4">{test.name}</Typography>
                  <Typography variant="body2" sx={{ display: "flex", alignItems: "center" }}>
                    <AccessTimeFilledOutlinedIcon sx={{ mr: 1 }} />
                    {test.timeOut} min
                  </Typography>
                </Stack>
              </Grid>
              <Grid item>
                {!onlyStart && (
                  <IconButton
                    onClick={() => navigate(R_TEST_QUESTIONS(test.id))}
                    size="small"
                    color="secondary"
                    title={t("Rozpocznij egzamin")}
                    sx={{ bgcolor: theme.palette.secondary.light + "33", mr: 1 }}
                  >
                    <PlayArrowOutlinedIcon />
                  </IconButton>
                )}
                <IconButton
                  onClick={() => navigate(isQuiz ? R_QUIZ_VIEW(test.id) : R_TEST_VIEW(test.id))}
                  size="large"
                  color="primary"
                  title={onlyStart ? t("Rozpocznij egzamin") : t("Szczegóły")}
                  sx={{ bgcolor: theme.palette.primary.light + "33" }}
                >
                  {onlyStart ? <PlayArrowOutlinedIcon /> : <VisibilityOutlinedIcon />}
                </IconButton>
              </Grid>
            </Grid>
          ))}
      </Paper>
    </ArrayLoadingSkeleton>
  );
}
