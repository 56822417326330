import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Grid,
  Paper,
  Box,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { SelectChangeEvent } from '@mui/material/Select';
import IGroup from "../../../types/IGroup";

export const GroupFilter = ({ list, onSelect }: { 
  list: Pick<IGroup, 'name' | 'id'>[]
  onSelect: (groupId: IGroup['id']) => void
 }) => {
  const [groupId, setGroupId] = useState(0);
  const { t } = useTranslation();
  const handleChange = (event: SelectChangeEvent<number>) => {
    const id = event.target.value as number;
    setGroupId(id);
    onSelect(id);
  }

  return <Grid item xs={12}>
    <Paper>
      <Box padding={2}>
        <FormControl fullWidth>
          <InputLabel id="demo-multiple-name-label">{t("Filtruj uczestników")}</InputLabel>
          <Select
            labelId="demo-multiple-name-label"
            id="user-test-groups"
            value={groupId || ''}
            onChange={handleChange}
            input={<OutlinedInput label={t("Filtruj uczestników")} />}
          >
            <MenuItem key={0} value={-1}>{t("Wszystkie grupy")}</MenuItem>
            {list.map((group) => (
              <MenuItem key={group.id} value={group.id}>
                {group.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText sx={{ m: '.5rem 0 0' }}>{t("Wybierz grupę uczestników, aby przefiltrować listę wyników poniżej.")}</FormHelperText>
        </FormControl>
      </Box>
    </Paper>
  </Grid>
}