import { useEffect, useState } from "react";
import ITest from "../../types/ITest";
import { getTests } from "../../services/test.service";
import useAlerts from "../../hooks/useAlerts";
import UserTestsList from "../user/components/UserTestsList";

export default function TestsAll(): JSX.Element {
  const { error } = useAlerts();

  const [tests, setTests] = useState<ITest[]>();

  useEffect(() => {
    getTests()
      .then(({ data }) => {
        setTests(data);
      })
      .catch((err) => {
        if (err.response.status !== 401) {
          error();
        }
      });
  }, []);

  return <UserTestsList tests={tests} />;
}
