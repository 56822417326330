import { Box, Paper, SvgIconTypeMap, Typography } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { useTranslation } from "react-i18next";

export default function HeaderWithIcon({
  title,
  icon: Icon,
  count,
  color = "primary"
}: Readonly<{
  title: string;
  icon: OverridableComponent<SvgIconTypeMap>;
  count?: number;
  color?: string;
}>) {
  const { t } = useTranslation();

  return (
    <Paper>
      <Box
        padding={2}
        sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography gutterBottom={false} variant="h5">
          {t(title)}
        </Typography>
        <Typography
          gutterBottom={false}
          variant="h4"
          color={color}
          sx={{ display: "inline-flex", alignItems: "center" }}
        >
          <Icon sx={{ marginRight: "4px", height: "1.2rem" }} /> {count ?? 0}
        </Typography>
      </Box>
    </Paper>
  );
}
