import {
  Box,
  Paper,
  Typography,
  Skeleton,
  Stack,
  Grid,
  Divider,
  List,
  ListItem,
  LinearProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ListItemAvatar,
  Avatar,
  CircularProgress
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getRide } from "../../services/card.service";
import useAlerts from "../../hooks/useAlerts";
import { getLanguage } from "../../utils/languagePicker";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import Chat from "../common/chat/Chat";
import { themePalette } from "../../themes/palette";

const ViewReport = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id = 0 } = useParams();

  const { error } = useAlerts();

  const [ride, setRide] = useState<any>();
  const [card, setCard] = useState<any>();
  const [comments, setComments] = useState<any>([]);

  function getMaxPoints(index: number) {
    return card.groups[index].items.reduce(function (prev: any, current: any) {
      return prev + current.points;
    }, 0);
  }

  function getAnswersPoints(groupId: number) {
    const groupItems = ride.card.groups.find((x: any) => x.id === groupId).items;
    let score = 0;

    for (let i = 0; i < groupItems.length; i++) {
      const currentId = groupItems[i].id;
      if (ride.answers[currentId] !== undefined) {
        score += ride.answers[currentId];
      }
    }

    return score;
  }

  useEffect(() => {
    let isMounted = false;

    if (id) {
      getRide(id)
        .then((data) => {
          if (isMounted) return;

          // if (data.data.endedAt) {
          data.data.card.groups.sort((a: any, b: any) => (a.index > b.index ? 1 : -1));

          setRide(data.data);
          setCard(data.data.card);
          setComments(data.data.comments);
          // } else {
          // warning(t("Ewaluacja trwa"));
          // navigate(R_RIDE(data.data.id));
          // }

          //todo odblokowanie widoku karty przed zapisem
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            error();
          }
        });
    }

    return () => {
      isMounted = true;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      {!ride && <Skeleton animation="wave" sx={{ width: "100%" }} />}
      {ride && (
        <Box>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} mb={2}>
            <Link to="" className="back_button" onClick={() => navigate(-1)} style={{ textDecoration: "none", color: themePalette.primary.main }}>
              <ArrowBackIcon sx={{ verticalAlign: 'top' }}/> <span>{t("Wstecz")}</span>
            </Link>
          </Stack>

          <Paper sx={{ p: 2 }}>
            <Typography marginBottom={2} component="h2" variant="h6">
              {t("Ogólne informacje")}
            </Typography>
            <Typography>
              {t("Data")}:{" "}
              <strong>
                {new Date(ride.createdAt).toLocaleDateString("pl-PL")}{" "}
                {new Date(ride.createdAt).toLocaleTimeString(["pl-PL"], {
                  hour: "2-digit",
                  minute: "2-digit"
                })}
              </strong>
            </Typography>
            <Typography>
              {t("Egzaminator")} - <strong>{ride?.instructor?.name}</strong>
            </Typography>
            <Typography>
              {t("Uczestnik")} - <strong>{ride?.user?.name}</strong> ({ride?.user?.email})
            </Typography>
            <Typography>
              {t("Ocena całościowa")} - <strong>{ride.result}%</strong>
            </Typography>
          </Paper>

          {/*//todo chat*/}

          <Grid marginTop={3} container spacing={3}>
            {card?.groups?.map((group: any, index: number) => (
              <Grid item key={index} xs={12} md={6}>
                <Paper>
                  <Box p={2}>
                    <Typography variant="h6">{group.name[getLanguage()]}</Typography>

                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box sx={{ width: "100%", mr: 1 }}>
                        <LinearProgress
                          variant="determinate"
                          value={(getAnswersPoints(group.id) / getMaxPoints(index)) * 100}
                        />
                      </Box>
                      <Box sx={{ minWidth: 35 }}>
                        <Typography variant="body2" color="text.secondary">
                          {getAnswersPoints(group.id)} / {getMaxPoints(index)}
                        </Typography>
                      </Box>
                    </Box>

                    <Divider />

                    <Box marginTop={0}>
                      <List>
                        {group?.items?.map((item: any, index: number) => (
                          <ListItem divider key={index}>
                            <ListItemAvatar>
                              <Avatar>
                                <Box
                                  sx={{
                                    position: "relative",
                                    display: "inline-flex"
                                  }}
                                >
                                  <CircularProgress
                                    variant="determinate"
                                    value={(ride.answers[item.id] / item.points) * 100}
                                  />
                                  <Box
                                    sx={{
                                      top: 0,
                                      left: 0,
                                      bottom: 0,
                                      right: 0,
                                      position: "absolute",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center"
                                    }}
                                  >
                                    <Typography
                                      variant="caption"
                                      component="div"
                                      color="text.secondary"
                                    >
                                      {ride.answers[item.id]} / {item.points}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Avatar>
                            </ListItemAvatar>
                            <Stack width={"100%"}>
                              <Accordion expanded={true} disableGutters>
                                <AccordionSummary>{item.name[getLanguage()]}</AccordionSummary>
                              </Accordion>
                              <Accordion disabled={!ride.report[item.id]}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                  {t("Raport")}
                                </AccordionSummary>
                                <AccordionDetails>
                                  {ride.report[item.id] && ride.report[item.id][getLanguage()]}
                                </AccordionDetails>
                              </Accordion>
                              <Accordion disabled={!ride.comments[item.id]}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                  {t("Komentarz")}
                                </AccordionSummary>
                                <AccordionDetails>{ride.comments[item.id]}</AccordionDetails>
                              </Accordion>
                            </Stack>
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
            ))}
          </Grid>

          {comments && comments.length > 0 && (
            <>
              <Box
                sx={{
                  display: "inline-flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%"
                }}
              >
                <Typography component="h2" variant="h6">
                  {t("Komentarze od instruktora")}
                </Typography>
              </Box>
              <Paper sx={{ p: { xs: 2, sm: 3 }, mt: 2, mb: 0 }}>
                {comments.map((comment: string, index: number) => (
                  <Box sx={{ mt: 0, mb: 1 }} key={index}>
                    <Typography>{comment}</Typography>
                  </Box>
                ))}
              </Paper>
            </>
          )}
        </Box>
      )}
      <Box marginTop={4} paddingBottom={2}>
        <Paper sx={{ pb: 2, pl: 1, pr: 1 }}>
          {ride?.id && <Chat chatKey={`ride-${ride.id}`} />}
        </Paper>
      </Box>
    </Box>
  );
};

export default ViewReport;
