import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useAlerts from "../../hooks/useAlerts";
import {
  getCompanyUserRides,
  getCompanyUsers,
  getCompanyUserTestsResults
} from "../../services/company.service";

import IUser from "../../types/IUser";
import ITest from "../../types/ITest";
import { useTranslation } from "react-i18next";
import UserReportsList from "./user/UserReportsList";
import ITestResult from "../../types/ITestResult";
import CompanyUserTestResultsList from "./user/CompanyUserTestResultsList";
// import ArrayLoadingSkeleton from "../../components/ArrayLoadingSkeleton";
// import { CompanyTestResultsList } from "./test/CompanyTestResultsList";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import HeaderWithIcon from "../../components/HeaderWithIcon";
import IReportWithCard from "../../types/IReportWithCard";
import { themePalette } from "../../themes/palette";
import StatsResultItem from "../../components/StatsResultItem";
import { R_TEST_VIEW } from "../../config/routes";
import StatUserItem from "../../components/StatsUserItem";
import { StatsUserList } from "../../components/StatsUserList";

const CompanyUser = (): JSX.Element => {
  const { t } = useTranslation();
  const { error } = useAlerts();
  const { id = 0 } = useParams();
  const navigate = useNavigate();

  const [testsResults, setTestsResults] = useState<(ITest & { results: ITestResult[] })[]>();
  const [rides, setRides] = useState<IReportWithCard[]>();
  const [user, setUser] = useState<IUser>();

  const groupList = [
    { id: 1, name: 'Oliver Hansen' },
    { id: 2, name: 'Oliver qwqw1' },
    { id: 3, name: 'Oliver asas2' },
    { id: 4, name: 'Oliver sdsd3' },
  ];

  /**
   * 1 Step in process
   * Get user in current view based on ID in url
   */
  useEffect(() => {
    getCompanyUsers()
      .then(({ data }) => {
        setUser(data.find((user) => user.role !== "Company" && user.id === +id));
      })
      .catch(() => {
        error();
      });
  }, [id]);

  /**
   * Step 2
   * When we have user get rest of the details
   */
  useEffect(() => {
    if (user) {
      getCompanyUserRides("" + user.id)
        .then(({ data }) => {
          setRides(
            data
              // .filter((report) => report.endedAt !== null) //TODO odblokowano pokazywanie niezakonczonych jazd
              // .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1)) // todo: sprawdzić czy to jest wane :)
          );
        })
        .catch((err) => {
          if (err.response?.status !== 401) { 
            error();
          }
        });

      // getCompanyUserTests("" + user.id)
      //   .then(({ data }) => {
      //     setTests(
      //       data.filter((test) => test.timeOut > 0).sort((a, b) => (a.name > b.name ? 1 : -1))
      //     );
      //   })
      //   .catch((err) => {
      //     if (err.response.status !== 401) {
      //       error();
      //     }
      //   });
      // TODO: what to do with this service it's curenlty not used, but mayby we need some logic to show here

      getCompanyUserTestsResults("" + user.id).then(({ data }) => {
        const reduced = data.reduce(
          (acc, { test, ...results }): (ITest & { results: ITestResult[] })[] => {
            const foundTest = acc.find((el) => el.id === test.id);

            if (foundTest) {
              foundTest.results.push(results);
            } else {
              acc.push({ ...test, results: [results] });
            }

            return acc;
          },
          [] as (ITest & { results: ITestResult[] })[]
        );

        setTestsResults(reduced);
      });
    }
  }, [user]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Link to="" className="back_button" onClick={() => navigate(-1)} style={{ textDecoration: "none", color: themePalette.primary.main }}>
          <ArrowBackIcon sx={{ verticalAlign: 'top' }}/> <span>{t("Wstecz")}</span>
        </Link>
      </Grid>

      <Grid item xs={12}>
        {user && (
          <Box component={Paper} p={2}>
            <Typography variant="h3">{user.name}</Typography>
            <Typography variant="h6">{user.email}</Typography>
          </Box>
        )}
      </Grid>

      <Grid item xs={12}>
        <Paper>
          <Box padding={2}>
            <Typography gutterBottom={false} variant="h5">
              {t("Testy")}
            </Typography>
          </Box>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <CompanyUserTestResultsList data={testsResults} />
      </Grid>

      <Grid item xs={12}>
        <HeaderWithIcon title={t("Ewaluacje uczestników")} count={rides?.length} icon={DescriptionOutlinedIcon} />
      </Grid>

      <Grid item xs={12}>
        <UserReportsList reports={rides} />
      </Grid>

      {/* Tutaj komponent działa prawidłowo dla tej listy */}
      <Grid item xs={12}>
        {/*<StatsUserList list={testsResults}/>*/}
      </Grid>

      {/* Tutaj musisz przygotować strukturę danych dla rides, eby były kompatybilne z StatsUserList */}
      {/* <Grid item xs={12}>
        <StatsUserList list={rides}/>
      </Grid> */}

    </Grid>
  );
};

export default CompanyUser;
