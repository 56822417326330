import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import {
  Alert,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography
} from "@mui/material";
import { useParams } from "react-router-dom";
import useAlerts from "../../hooks/useAlerts";
import { BACKEND_URL } from "../../config/config";
import { getCompanyUsers, getCompanyUserTestResult } from "../../services/company.service";
import { useTranslation } from "react-i18next";
import ITest from "../../types/ITest";
import ITestResult from "../../types/ITestResult";
import { getFormattedDate } from "../../utils/dateUtils";
import IUser from "../../types/IUser";
import ArrayLoadingSkeleton from "../../components/ArrayLoadingSkeleton";
import TestResult from "../common/test/TestResult";
import ITestResultWithTestAndUser from "../../types/ITestResultWithTestAndUser";

const CompanyTest = (): JSX.Element => {
  console.log("CompanyTest");

  const { t } = useTranslation();
  const { error } = useAlerts();
  const { id = 0 } = useParams();
  const [test, setTest] = useState<ITestResult & { user: IUser; test: ITest }>();
  const [goodAnswers, setGoodAnswers] = useState<any>();
  const [userAnswers, setUserAnswers] = useState<any>();
  const [questions, setQuestions] = useState<any>();
  const [whenSucceed, setWhenSucceeed] = useState<number>(70);
  const [users, setUsers] = useState<any>();
  // const navigate = useNavigate();

  const checkAnswerToCheckbox = (qID: number, aKey: string) => {
    if (goodAnswers.length !== 0 && goodAnswers[qID]) {
      return goodAnswers[qID].some((element: string) => {
        return element === aKey;
      });
    }
  };

  const checkAnswerClassName = (qID: number, aKey: string) => {
    if (userAnswers.length !== 0 && userAnswers[qID]) {
      return userAnswers[qID].some((element: string) => {
        return element === aKey;
      });
    }
  };

  useEffect(() => {
    let isMounted = true;
    getCompanyUserTestResult(id)
      .then((data) => {
        if (isMounted) {
          setTest(data.data);

          setGoodAnswers(data.data.questions);
          setUserAnswers(data.data.answers);

          setWhenSucceeed(data.data.test.whenSucceed);

          data.data.test.questions?.sort((a: any, b: any) => (a.id > b.id ? 1 : -1));
          setQuestions(data.data.test.questions);
        }
      })
      .catch((err) => {
        console.error(err);
        error(t("Ups, coś poszło nie tak"));
      });

    getCompanyUsers()
      .then((data) => {
        if (isMounted) return;

        setUsers(data.data.filter((user: any) => user.role !== "Company"));
      })
      .catch((err) => {
        if (err.response.status !== 401) {
          error();
        }
      });

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const user: IUser = users?.find((x: any) => x.id == test?.user);

  if (user) {
    return (
      <ArrayLoadingSkeleton data={[test]}>
        <TestResult test={{ ...test, user } as ITestResultWithTestAndUser} />
      </ArrayLoadingSkeleton>
    );
  }
  return (
    <Box>
      {!test?.endedAt && <Alert severity="warning">{t("Egzamin nie został ukończony!")}</Alert>}
      {test?.endedAt && (
        <Paper>
          <Typography variant="body1">
            {t("Uzyskany wynik")} {test.result}% -{" "}
            <strong>{test.result > whenSucceed ? t("ZDANY") : t("NIEZDANY")}</strong>
          </Typography>

          {users && (
            <Typography variant="body1">
              <strong>{users.find((x: any) => x.id == test.user).name}</strong> (
              {users.find((x: any) => x.id == test.user).email})
            </Typography>
          )}
          <Typography variant="body1">
            {t("Rozpoczęcie")}: {getFormattedDate(test.createdAt).date}{" "}
            <strong>{getFormattedDate(test.createdAt).time}</strong>
          </Typography>
          <Typography variant="body1">
            {t("Zakończenie")}: {getFormattedDate(test.endedAt).date}{" "}
            <strong>{getFormattedDate(test.endedAt).time}</strong>
          </Typography>
        </Paper>
      )}
      {test?.endedAt &&
        questions &&
        questions?.length !== 0 &&
        questions?.map((question: any, index: number) => {
          return (
            <Box sx={{ flexGrow: 1 }} key={question.id}>
              <Paper
                square
                sx={{
                  p: { xs: 2, sm: 3 },
                  mt: 1,
                  mb: 4,
                  borderRadius: "8px"
                }}
              >
                <>
                  <Box sx={{ mb: 3, display: "flex", justifyContent: "end" }}>
                    <Typography>
                      {t("Pytanie")} {index + 1}/{questions?.length}
                    </Typography>
                  </Box>
                  {question.media && (
                    <Box sx={{ width: "100%" }}>
                      <Box
                        component="img"
                        sx={{
                          display: "block",
                          overflow: "hidden",
                          maxHeight: "80vh",
                          height: "100%",
                          width: "100%",
                          margin: "1rem auto"
                        }}
                        src={
                          question.media?.url
                            ? `${BACKEND_URL}files/open/${question.media?.key}`
                            : ""
                        }
                        alt={t("Zdjęcie do pytania")}
                      />
                    </Box>
                  )}
                  <Box sx={{ pt: 2, textAlign: "center" }}>
                    <Typography>{question.question?.pl}</Typography>
                  </Box>
                  <Box sx={{ pt: 2 }}>
                    <List sx={{ width: "100%" }}>
                      {Object.keys(question.answers).map((key) => {
                        if (question.answers[key].pl === "") {
                          return null;
                        }

                        const labelId = `checkbox-list-label-${key}`;
                        const yourAnswer = checkAnswerClassName(question.id, key);
                        const goodAnswer = checkAnswerToCheckbox(question.id, key);

                        const className =
                          yourAnswer === true
                            ? goodAnswer === true
                              ? "good_answer"
                              : "bad_answer"
                            : "";

                        return (
                          <ListItem key={key} disablePadding className={className}>
                            <ListItemButton dense sx={{ paddingLeft: 0 }}>
                              <ListItemIcon className="test_checkbox">
                                <Checkbox
                                  edge="start"
                                  checked={goodAnswer}
                                  tabIndex={-1}
                                  disableRipple
                                  inputProps={{ "aria-labelledby": labelId }}
                                  disabled
                                />
                              </ListItemIcon>
                              <ListItemText id={labelId} primary={question.answers[key].pl} />
                            </ListItemButton>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Box>
                </>
              </Paper>
            </Box>
          );
        })}
    </Box>
  );
};

export default CompanyTest;
